import log from "loglevel";
import type { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

// page component
import { LoginPage } from "@/components/pages/login/LoginPage";

export default LoginPage;

export const getStaticProps: GetStaticProps = async context => {
  try {
    return {
      props: {
        ...(await serverSideTranslations(context.locale as string, [
          "common",
          "login",
          "login-drawer",
          "errors"
        ]))
      }
    };
  } catch (e) {
    log.error("%0", e);

    return {
      props: {
        notFound: true
      }
    };
  }
};
