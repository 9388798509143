import React from "react";

import { useAuth, useLogin } from "@/customHooks/useAuth";
import { useFormik } from "formik";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { AccountApiNS } from "~types/api/account";

import { useLoginFormContext } from "@/components/blocks/login/login-drawer/context";
import { MemoizedTextField } from "@/components/elements/memoized-textfield/MemoizedTextField";
import { PasswordField } from "@/components/elements/password-field/PasswordField";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";

import ArrowLeftIcon from "~public/icons/Arrow Left/Arrow Left.svg";
import CloseIcon from "~public/icons/Close/Close.svg";
import FacebookIcon from "~public/icons/Facebook/Facebook.svg";
import GoogleIcon from "~public/icons/Google/Google.svg";

import LoginArgs = AccountApiNS.LoginArgs;

export const SigninForm = (): JSX.Element => {
  const router = useRouter();
  const { t } = useTranslation("login-drawer");
  const { login, isLoading, error, isSuccess } = useLogin();
  const { handleCloseDrawer, setFormType } = useLoginFormContext();
  const { session } = useAuth();
  const theme = useTheme();
  const isGreaterThanTablet = useMediaQuery(theme.breakpoints.up("md"));
  const isGreaterThanMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const formik = useFormik<LoginArgs>({
    //TODO remove test values

    initialValues: {
      email: "",
      password: ""
    },
    onSubmit: values => {
      login("credentials", {
        data: values,
        onSuccess: res => {
          router.replace("/");
        }
      });
    }
  });

  const handleSignUp = () => {
    setFormType("signup");
  };
  const handleForgotPassword = () => {
    setFormType("recover");
  };

  const handleGoogleSignin = () => {
    login("google");
  };

  const handleFacebookSignin = () => {
    login("facebook");
  };

  return (
    <Box
      component={"form"}
      onSubmit={(e: any) => formik.handleSubmit(e)}
      sx={{
        height: "100%",
        width: {
          md: "100%"
        },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: {
          md: "center"
        },
        "& > *:not(:first-child)": {
          maxWidth: { md: "480px" }
        }
      }}>
      {isGreaterThanTablet && (
        <Box
          sx={{
            mt: {
              xs: 1,
              md: 4
            },
            display: "flex",
            gap: 0.5,
            alignItems: "center",
            justifyContent: "end",
            alignSelf: {
              md: "space-between"
            },
            width: {
              md: "100%"
            }
          }}>
          <IconButton
            sx={{
              ml: 0,
              mr: "auto",
              "& path": {
                fill: { xs: "white", md: theme.palette.primary.main }
              },
              "& [stroke-width='1.2']": {
                stroke: { xs: "white", md: theme.palette.primary.main }
              }
            }}
            onClick={handleCloseDrawer}>
            <ArrowLeftIcon />
          </IconButton>
          <Typography
            sx={{
              color: {
                xs: "white.main",
                md: "primary.light"
              },
              fontSize: {
                md: 18
              }
            }}>
            {t("already-registered")}
          </Typography>
          <Button
            color={"orange"}
            variant={"text"}
            sx={{
              fontWeight: 600,
              textTransform: "capitalize",
              fontSize: {
                md: 20
              }
            }}
            onClick={handleSignUp}>
            {t("signup")}
          </Button>
        </Box>
      )}
      <Box
        mb={2.5}
        sx={{
          mt: {
            xs: 0.75,
            md: 15
          },
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
        <Typography
          sx={{
            flex: 1,
            ml: "auto",
            fontSize: {
              xs: 24,
              md: 48
            }
          }}
          textAlign={"center"}
          fontWeight={500}>
          {t("signin-form.title")}
        </Typography>
        {!isGreaterThanTablet && (
          <IconButton
            sx={{ position: "absolute", ml: "auto", right: 0 }}
            onClick={handleCloseDrawer}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: { xs: 1.25, md: 2 },
          justifyContent: "center",
          alignItems: "start",
          width: {
            md: "100%"
          },
          flexDirection: {
            md: "column"
          }
        }}>
        <Button
          color={!isGreaterThanMobile ? "white" : "primary"}
          variant={"outlined"}
          onClick={handleGoogleSignin}
          sx={{
            width: {
              sm: "100%"
            },
            flex: 1,
            height: 44,
            borderColor: {
              xs: "#D0D5DD",
              md: "primary.light"
            },
            maxWidth: { xs: 100, sm: "unset" },
            bgcolor: "white.main",
            fontSize: {
              xs: 16,
              md: 18
            },
            color: {
              md: "primary.main"
            },
            textTransform: {
              md: "uppercase"
            },
            borderWidth: 1,
            "& > svg": {
              marginLeft: {
                sm: 1.5
              },
              fontSize: 24
            }
          }}>
          {isGreaterThanMobile ? t("login:signup-google") : ""}
          <GoogleIcon />
        </Button>
        <Button
          color={!isGreaterThanMobile ? "white" : "primary"}
          variant={"outlined"}
          onClick={handleFacebookSignin}
          sx={{
            width: {
              sm: "100%"
            },
            flex: 1,
            height: 44,
            borderColor: {
              xs: "#D0D5DD",
              md: "primary.light"
            },
            maxWidth: { xs: 100, sm: "unset" },
            bgcolor: "white.main",
            fontSize: {
              xs: 16,
              md: 18
            },
            color: {
              md: "primary.light"
            },
            textTransform: {
              md: "uppercase"
            },
            borderWidth: 1,
            "& > svg": {
              fontSize: 24,
              marginLeft: {
                sm: 1.5
              },
              "& path": {
                fill: {
                  xs: "black",
                  md: "#1877F2"
                }
              }
            }
          }}>
          {isGreaterThanMobile ? t("login:signup-facebook") : ""}
          <FacebookIcon />
        </Button>
      </Box>
      <Divider sx={{ my: 2, alignItems: "center", width: "100%" }}>
        <Typography mx={1} mb={-1} color={"#D0D5DD"} textAlign={"center"}>
          {t("signin-form.or")}
        </Typography>
      </Divider>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        gap={1.25}>
        <MemoizedTextField
          autoFocus
          id={"signin-email"}
          data-qa="signin-textfield-email"
          type={"email"}
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          fullWidth
          label={t("signin-form.fields.email.label")}
          placeholder={t("signin-form.fields.email.placeholder")}
          error={!isLoading && Boolean(error)}
        />
        <PasswordField
          id={"signin-password"}
          data-qa="signin-textfield-password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          fullWidth
          label={t("signin-form.fields.password.label")}
          placeholder={t("signin-form.fields.password.placeholder")}
          error={!isLoading && Boolean(error)}
          helperText={!isLoading && error}
        />
        <Button
          color={"pink"}
          onClick={handleForgotPassword}
          variant={"text"}
          sx={{
            mt: 2,
            textTransform: "none",
            fontSize: {
              xs: 14,
              md: 18
            }
          }}>
          {t("signin-form.forgot-password")}
        </Button>
      </Box>
      <Box
        sx={{
          mt: {
            xs: "auto",
            md: 5
          },
          width: {
            md: "100%"
          }
        }}
        mb={1.5}>
        <Button
          type={"submit"}
          fullWidth
          color={"green"}
          disabled={isLoading}
          endIcon={isLoading && <CircularProgress size={20} />}
          data-qa="signin-button-continue"
          data-cy="signin-button-continue"
          sx={{ fontSize: { xs: 14, md: 18 } }}>
          {t("continue")}
        </Button>
        <Box
          mt={2}
          mb={2}
          sx={{
            flex: 1,
            gap: 0.5,
            alignItems: "center",
            justifyContent: "center",
            display: {
              xs: "flex",
              md: "none"
            }
          }}>
          <Typography>{t("already-registered")}</Typography>
          <Button
            color={"pink"}
            onClick={handleSignUp}
            variant={"text"}
            sx={{
              fontWeight: 400,
              fontSize: 16,
              textTransform: "capitalize"
            }}
            data-qa="signin-button-signup"
            data-cy="signin-button-signup">
            {t("signup")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
