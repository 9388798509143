import * as yup from "yup";
import { useTranslation } from "next-i18next";
import { UserProfile } from "~types/profile";

interface initialValues {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password: string;
  user_type: UserProfile["user_type"];
}

export const useSignupValidation = () => {
  const { t } = useTranslation();

  return yup.object({
    email: yup
      .string()
      .email("Enter valid email")
      .required(t("errors:field_required.email")),
    first_name: yup.string().required(t("errors:field_required.default")),
    last_name: yup.string().required(t("errors:field_required.default"))
  });
};

export const validatePassword = (
  values: Pick<initialValues, "password" | "confirm_password">
) => {
  let errors = {} as any;
  const numRegEx = /\d/;
  const symbolRegEx = /[~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/]/;

  if (!numRegEx.test(values.password)) {
    errors.password = { ...errors.password, contain_num: true };
  }

  if (values.password === values.password?.toLowerCase()) {
    errors.password = { ...errors.password, uppercase: true };
  }

  if (values.password?.length < 8 || values.password?.length > 64) {
    errors.password = { ...errors.password, length: true };
  }

  if (!symbolRegEx.test(values.password)) {
    errors.password = { ...errors.password, symbols: true };
  }

  if (values.confirm_password !== values.password) {
    errors.confirm_password = "Passwords don't match";
  }

  return errors;
};
