import React from "react";

import AccountApi from "@/api/Account";

import { useFormik } from "formik";
import { useTranslation } from "next-i18next";

import { useLoginFormContext } from "@/components/blocks/login/login-drawer/context";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";

import ArrowLeftIcon from "~public/icons/Arrow Left/Arrow Left.svg";
import ArrowRightLong from "~public/icons/Arrow Right Long/Arrow Right Long.svg";
import CloseIcon from "~public/icons/Close/Close.svg";

const initialValues = {
  email: ""
};

export const RecoverForm = (): JSX.Element => {
  const { t } = useTranslation("login-drawer");
  const { handleCloseDrawer, setFormType } = useLoginFormContext();
  const theme = useTheme();
  const isGreaterThanTablet = useMediaQuery(theme.breakpoints.up("md"));

  const formik = useFormik<typeof initialValues>({
    initialValues,
    onSubmit: async (values, formikHelpers) => {
      try {
        formikHelpers.setSubmitting(true);
        const response = await AccountApi.resetPassword(values);
        setFormType("recover-email-sent");
      } catch (e) {
        if (e.response && e.response.status === 400) {
          if (e.response.data && e.response.data.email) {
            return formikHelpers.setFieldError(
              "email",
              t("errors:invalid.email")
            );
          }

          return formikHelpers.setFieldError(
            "email",
            t("errors:not_match.email")
          );
        }
      } finally {
        formikHelpers.setSubmitting(false);
      }
    }
  });

  const handleBackButton = () => {
    setFormType("signin");
  };

  return (
    <Box
      onSubmit={formik.handleSubmit}
      component={"form"}
      sx={{
        height: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start"
      }}>
      <Box
        mt={0.75}
        mb={2.5}
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: { md: "column" },
          width: {
            md: "100%"
          },
          "& > *:not(:first-child)": {
            maxWidth: { md: "480px" }
          }
        }}>
        {isGreaterThanTablet && (
          <Box
            sx={{
              mt: {
                xs: 1,
                md: 4
              },
              width: "100%"
            }}>
            <IconButton
              sx={{
                ml: 0,
                mr: "auto",
                "& path": {
                  fill: { xs: "white", md: theme.palette.primary.main }
                },
                "& [stroke-width='1.2']": {
                  stroke: { xs: "white", md: theme.palette.primary.main }
                }
              }}
              onClick={handleBackButton}>
              <ArrowLeftIcon />
            </IconButton>
          </Box>
        )}
        {!isGreaterThanTablet && (
          <IconButton
            sx={{ position: "absolute", left: 0 }}
            onClick={handleBackButton}>
            <ArrowLeftIcon />
          </IconButton>
        )}
        <Typography
          sx={{
            mt: {
              md: 15
            },
            flex: 1,
            ml: { xs: "auto", md: 0 },
            fontSize: { xs: 24, md: 48 }
          }}
          textAlign={"center"}
          fontWeight={500}>
          {t("recover-form.title")}
        </Typography>
        {!isGreaterThanTablet && (
          <IconButton
            sx={{ position: "absolute", right: 0 }}
            onClick={handleCloseDrawer}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        sx={{
          maxWidth: {
            md: "480px"
          }
        }}>
        <Typography
          sx={{
            fontSize: {
              xs: 14,
              md: 18
            },
            mb: {
              xs: 2,
              md: 2.5
            }
          }}>
          {t("recover-form.subtitle")}
        </Typography>
        <TextField
          name="email"
          type={"email"}
          error={Boolean(formik.errors.email)}
          helperText={formik.errors.email}
          value={formik.values.email}
          onChange={formik.handleChange}
          fullWidth
          label={t("recover-form.fields.email.label")}
          placeholder={t("recover-form.fields.email.placeholder")}
        />
      </Box>
      <Box
        mb={1.5}
        sx={{
          width: { md: "100%" },
          maxWidth: {
            md: "480px"
          },
          mt: {
            xs: "auto",
            md: 5
          }
        }}>
        <Button
          type={"submit"}
          startIcon={formik.isSubmitting && <CircularProgress size={20} />}
          disabled={formik.isSubmitting}
          fullWidth
          color={"green"}
          variant={"contained"}
          endIcon={<ArrowRightLong />}>
          {t("continue")}
        </Button>
      </Box>
    </Box>
  );
};
