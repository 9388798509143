import React from "react";

import { EmailSent } from "@/components/blocks/login/email-sent/EmailSent";
import { useLoginFormContext } from "@/components/blocks/login/login-drawer/context";
import { RecoverForm } from "@/components/blocks/login/recover-form/RecoverForm";
import { SigninForm } from "@/components/blocks/login/signin-form/SigninForm";
import { SignupForm } from "@/components/blocks/login/signup-form/SignupForm";
import { Box } from "@mui/material";

export const LoginDrawerDesktop = (): JSX.Element => {
  const { formType, isDrawerOpen } = useLoginFormContext();
  if (isDrawerOpen)
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: {
            xs: "100%",
            md: "50%"
          },
          px: {
            md: 6,
            lg: 12
          }
        }}>
        {formType === "signup" && <SignupForm />}
        {formType === "signin" && <SigninForm />}
        {formType === "recover" && <RecoverForm />}
        {formType === "signup-email-sent" && <EmailSent formType={"signup"} />}
        {formType === "recover-email-sent" && (
          <EmailSent formType={"recover"} />
        )}
      </Box>
    );

  return <></>;
};
