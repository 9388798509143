import React from "react";

export type FormType =
  | "signin"
  | "signup"
  | "recover"
  | "recover-email-sent"
  | "signup-email-sent";

export interface LoginFormContextValue {
  formType: FormType;
  setFormType: React.Dispatch<React.SetStateAction<FormType>>;
  isDrawerOpen: boolean;
  handleCloseDrawer: () => void;
  handleOpenDrawer: () => void;
}

const contextDefaultValue = {} as LoginFormContextValue;

export const LoginFormContext = React.createContext(contextDefaultValue);

export const useLoginFormContext = () => React.useContext(LoginFormContext);
