import React from "react";

import { useTranslation } from "next-i18next";

import { useLoginFormContext } from "@/components/blocks/login/login-drawer/context";
import { Box, Button, Typography } from "@mui/material";

import EmailSentIcon from "~public/images/email-sent.svg";

interface EmailSentProps {
  formType: "recover" | "signup";
}

export const EmailSent = ({ formType }: EmailSentProps): JSX.Element => {
  const { t } = useTranslation("login-drawer");
  const { setFormType } = useLoginFormContext();

  const handleBackButton = () => {
    setFormType("signin");
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 1.25,
        position: "relative"
      }}>
      <Typography
        fontWeight={400}
        sx={{
          fontSize: {
            xs: 18,
            md: 20
          }
        }}>
        {t("email-sent.title")}
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: 14,
            md: 18
          }
        }}>
        {t("email-sent.subtitle")}
      </Typography>
      <Box
        mt={3}
        mb={5}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          "& > svg": {
            fontSize: {
              xs: 156,
              md: 202
            }
          }
        }}>
        <EmailSentIcon />
      </Box>
      <Typography>
        {formType === "signup" && t("email-sent.signup")}
        {formType === "recover" && t("email-sent.recover")}
      </Typography>
      <Button
        color={"orange"}
        variant={"text"}
        sx={{ textTransform: "none", textDecoration: "underline" }}>
        Send it again
      </Button>
      <Button
        color={"green"}
        onClick={handleBackButton}
        fullWidth
        sx={{ position: "absolute", bottom: 20 }}>
        {"Back to Sign In"}
      </Button>
    </Box>
  );
};
