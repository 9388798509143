import React from "react";

import { EmailSent } from "@/components/blocks/login/email-sent/EmailSent";
import { useLoginFormContext } from "@/components/blocks/login/login-drawer/context";
import { RecoverForm } from "@/components/blocks/login/recover-form/RecoverForm";
import { SigninForm } from "@/components/blocks/login/signin-form/SigninForm";
import { SignupForm } from "@/components/blocks/login/signup-form/SignupForm";
import { Container, Drawer } from "@mui/material";

export const LoginDrawer = (): JSX.Element => {
  const { formType, isDrawerOpen, handleCloseDrawer } = useLoginFormContext();
  return (
    <Drawer
      sx={{ width: "100vw" }}
      anchor={"right"}
      open={isDrawerOpen}
      onClose={handleCloseDrawer}>
      <Container
        sx={{
          height: "100%",
          width: "100vw",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          pt: 2.75
        }}>
        {formType === "signup" && <SignupForm />}
        {formType === "signin" && <SigninForm />}
        {formType === "recover" && <RecoverForm />}
        {formType === "signup-email-sent" && <EmailSent formType={"signup"} />}
        {formType === "recover-email-sent" && (
          <EmailSent formType={"recover"} />
        )}
      </Container>
    </Drawer>
  );
};
