import React, { useState } from "react";

import { useRegister } from "@/customHooks/useAuth";
import { useFormik } from "formik";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { toast } from "react-hot-toast";
import { UserProfile } from "~types/profile";

import { useLoginFormContext } from "@/components/blocks/login/login-drawer/context";
import {
  useSignupValidation,
  validatePassword
} from "@/components/blocks/login/signup-form/signupValidation";
import { MemoizedTextField } from "@/components/elements/memoized-textfield/MemoizedTextField";
import { PasswordField } from "@/components/elements/password-field/PasswordField";
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";

import ArrowLeftIcon from "~public/icons/Arrow Left/Arrow Left.svg";
import CloseIcon from "~public/icons/Close/Close.svg";

const ErrorElement = React.memo(
  ({ isValid, text }: { isValid: boolean; text: string }) => {
    const theme = useTheme();
    return (
      <Box
        mt={1.25}
        sx={{ display: "flex", columnGap: 1, alignItems: "center" }}>
        <Box
          component={"span"}
          width={8}
          height={8}
          borderRadius={2}
          bgcolor={
            !isValid ? theme.palette.error.dark : theme.palette.success.dark
          }
        />
        <Typography color={"#667085"} fontSize={12} lineHeight={1.25}>
          {text}
        </Typography>
      </Box>
    );
  }
);
ErrorElement.displayName = "ErrorElement";

interface PasswordRequires {
  password: {
    contain_num?: boolean;
    uppercase?: boolean;
    length?: boolean;
    symbols?: boolean;
  };
}

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  confirm_password: "",
  user_type: 1 as UserProfile["user_type"]
} as const;

export const SignupForm = (): JSX.Element => {
  const router = useRouter();
  const refToken = router.query.ref?.toString();
  const theme = useTheme();
  const { t } = useTranslation("login-drawer");
  const { handleCloseDrawer, setFormType } = useLoginFormContext();
  const isGreaterThanTablet = useMediaQuery(theme.breakpoints.up("md"));
  const { register, isLoading, error } = useRegister();
  const [passwordRequires, setPasswordRequires] = useState<PasswordRequires>({
    password: {}
  });
  const validationSchema = useSignupValidation();

  const formik = useFormik<typeof initialValues>({
    initialValues,
    validateOnChange: false,
    validate: validatePassword,
    validationSchema: validationSchema,
    onSubmit: values => {
      const registerData = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password: values.password,
        user_type: values.user_type,
        ref_token: refToken
      } as const;
      register(registerData, {
        onSuccess: () => {
          toast.success("You are successfully registered");
          setFormType("signup-email-sent");
        }
      });
    }
  });

  const handlePasswordChange: StandardInputProps["onChange"] = e => {
    formik.handleChange(e);
    setPasswordRequires(
      validatePassword({
        password: e.target.value,
        confirm_password: formik.values.confirm_password
      })
    );
  };

  const clearErrorOnFocus: StandardInputProps["onFocus"] = e => {
    if (formik.errors[e.target.name as keyof typeof initialValues]) {
      formik.setFieldError(e.target.name, undefined);
    }
  };

  const handleSignIn = () => {
    setFormType("signin");
  };

  return (
    <Box
      component={"form"}
      onSubmit={formik.handleSubmit}
      sx={{
        height: "100%",
        width: {
          md: "100%"
        },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: {
          md: "center"
        },
        "& > *:not(:first-child)": {
          maxWidth: { md: "480px" }
        }
      }}>
      {isGreaterThanTablet && (
        <Box
          sx={{
            mt: {
              xs: 1,
              md: 4
            },
            display: "flex",
            gap: 0.5,
            alignItems: "center",
            justifyContent: "end",
            alignSelf: {
              md: "space-between"
            },
            width: {
              md: "100%"
            }
          }}>
          <IconButton
            sx={{
              ml: 0,
              mr: "auto",
              "& path": {
                fill: { xs: "white", md: theme.palette.primary.main }
              },
              "& [stroke-width='1.2']": {
                stroke: { xs: "white", md: theme.palette.primary.main }
              }
            }}
            onClick={handleCloseDrawer}>
            <ArrowLeftIcon />
          </IconButton>
          <Typography
            sx={{
              color: {
                xs: "white.main",
                md: "primary.light"
              },
              fontSize: {
                md: 18
              }
            }}>
            {t("already-registered")}
          </Typography>
          <Button
            color={"orange"}
            variant={"text"}
            sx={{
              fontWeight: 600,
              textTransform: "capitalize",
              fontSize: {
                md: 20
              }
            }}
            onClick={handleSignIn}>
            {t("signin")}
          </Button>
        </Box>
      )}
      <Box
        mb={2.5}
        sx={{
          mt: {
            xs: 0.75,
            md: 15
          },
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
        <Typography
          sx={{
            flex: 1,
            ml: "auto",
            fontSize: {
              xs: 24,
              md: 48
            }
          }}
          textAlign={"center"}
          fontWeight={500}>
          {t("signup-form.title")}
        </Typography>
        {!isGreaterThanTablet && (
          <IconButton
            sx={{ position: "absolute", ml: "auto", right: 0 }}
            onClick={handleCloseDrawer}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      <Box sx={{ gap: 1.25, display: "flex", flexDirection: "column" }}>
        <MemoizedTextField
          autoFocus
          id={"signup-first_name"}
          data-qa="first_name_textfield"
          name="first_name"
          value={formik.values.first_name}
          onFocus={clearErrorOnFocus}
          onChange={formik.handleChange}
          fullWidth
          label={t("signup-form.fields.first_name.label")}
          placeholder={t("signup-form.fields.first_name.placeholder")}
          error={Boolean(formik.errors.first_name)}
          helperText={formik.errors.first_name}
        />
        <MemoizedTextField
          id={"signup-last_name"}
          data-qa="signup-textfield-last_name"
          name="last_name"
          value={formik.values.last_name}
          onFocus={clearErrorOnFocus}
          onChange={formik.handleChange}
          fullWidth
          label={t("signup-form.fields.last_name.label")}
          placeholder={t("signup-form.fields.last_name.placeholder")}
          error={Boolean(formik.errors.last_name)}
          helperText={formik.errors.last_name}
        />
        <MemoizedTextField
          id={"signup-email"}
          data-qa="signup-textfield-email"
          name="email"
          type={"email"}
          value={formik.values.email}
          onFocus={clearErrorOnFocus}
          onChange={formik.handleChange}
          fullWidth
          label={t("signup-form.fields.email.label")}
          placeholder={t("signup-form.fields.email.placeholder")}
          error={Boolean(formik.errors.email)}
          helperText={formik.errors.email}
        />
        <PasswordField
          id={"signup-password"}
          data-qa="signup-textfield-password"
          name="password"
          value={formik.values.password}
          onFocus={clearErrorOnFocus}
          onChange={handlePasswordChange}
          fullWidth
          autoComplete={"off"}
          autoCorrect={"false"}
          label={t("signup-form.fields.password.label")}
          placeholder={t("signup-form.fields.password.placeholder")}
          error={Boolean(formik.errors.password)}
        />
        <PasswordField
          id={"signup-confirm_password"}
          data-qa="signup-textfield-confirm_password"
          name="confirm_password"
          value={formik.values.confirm_password}
          onFocus={clearErrorOnFocus}
          onChange={handlePasswordChange}
          fullWidth
          autoComplete={"off"}
          autoCorrect={"false"}
          label={t("signup-form.fields.confirm_password.label")}
          placeholder={t("signup-form.fields.confirm_password.placeholder")}
          error={Boolean(formik.errors.confirm_password)}
          helperText={formik.errors.confirm_password}
        />
        {!isLoading && (
          <FormHelperText sx={{ color: theme.palette.error.main }}>
            {error}
          </FormHelperText>
        )}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: 2,
            rowGap: 0.75
          }}>
          <ErrorElement
            isValid={formik.dirty && !passwordRequires.password?.contain_num}
            text={t("signup-form.fields.password.error.numbers")}
          />
          <ErrorElement
            isValid={formik.dirty && !passwordRequires.password?.uppercase}
            text={t("signup-form.fields.password.error.uppercase-letters")}
          />
          <ErrorElement
            isValid={formik.dirty && !passwordRequires.password?.length}
            text={t("signup-form.fields.password.error.length")}
          />
          <ErrorElement
            isValid={formik.dirty && !passwordRequires.password?.symbols}
            text={t("signup-form.fields.password.error.symbols")}
          />
        </Box>
      </Box>
      <Box
        mb={1.5}
        sx={{
          mt: {
            xs: "auto",
            md: 4
          },
          width: {
            md: "100%"
          }
        }}>
        <Button
          disabled={isLoading || !formik.isValid}
          onClick={() => formik.handleSubmit()}
          fullWidth
          color={"green"}
          endIcon={isLoading && <CircularProgress size={20} />}
          data-qa="signup-button-continue"
          data-cy="signup-button-continue"
          sx={{ fontSize: { xs: 14, md: 18 } }}>
          {t("continue")}
        </Button>
        {!isGreaterThanTablet && (
          <Box
            mt={2}
            mb={2}
            sx={{
              flex: 1,
              display: "flex",
              gap: 0.5,
              alignItems: "center",
              justifyContent: "center"
            }}>
            <Typography>{t("already-registered")}</Typography>
            <Button
              onClick={handleSignIn}
              variant={"text"}
              color={"orange"}
              sx={{
                fontWeight: 400,
                fontSize: 16,
                textTransform: "capiatlize"
              }}
              data-cy="signup-button-signin"
              data-qa="signup-button-signin">
              {t("signin")}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
