import React, { useEffect, useState } from "react";

import AccountApi from "@/api/Account";

import { useLogin } from "@/customHooks/useAuth";
import { useTranslation } from "next-i18next";
import Head from "next/head";
import Image from "next/image";
import { useRouter } from "next/router";

import { LoginDrawer } from "@/components/blocks/login/login-drawer/LoginDrawer";
import { LoginDrawerDesktop } from "@/components/blocks/login/login-drawer/LoginDrawerDesktop";
import {
  FormType,
  LoginFormContext
} from "@/components/blocks/login/login-drawer/context";
import DefaultLink from "@/components/elements/link/DefaultLink";
import Layout from "@/components/layouts/layout/Layout";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";

import ArrowLeftIcon from "~public/icons/Arrow Left/Arrow Left.svg";
import FacebookIcon from "~public/icons/Facebook/Facebook.svg";
import GoogleIcon from "~public/icons/Google/Google.svg";
import RadioChecked from "~public/icons/Radio Button/Checked.svg";
import RadioUnchecked from "~public/icons/Radio Button/Unchecked.svg";

export const LoginPage = (): JSX.Element => {
  const { t } = useTranslation("login");
  const router = useRouter();
  const { login } = useLogin();
  const theme = useTheme();
  const isGreaterThanTablet = useMediaQuery(theme.breakpoints.up("md"));
  const isGreaterThanMobile = useMediaQuery(theme.breakpoints.up("sm"));

  const isValidFormType = ["signin", "recover", "signup"].some(
    el => el === router.query.formType?.toString()
  );
  const [formType, setFormType] = useState<FormType>("signin");
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isSubscribeChecked, setIsSubscribeChecked] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState("/images/homepage/image1.png");

  useEffect(() => {
    if (!isDrawerOpen || !isGreaterThanTablet) {
      setImageSrc("/images/homepage/image1.png");
      return;
    }

    switch (formType) {
      case "signin":
        setImageSrc("/images/homepage/image5.png");
        break;
      case "signup":
        setImageSrc("/images/homepage/image4.png");
        break;
      case "recover":
        setImageSrc("/images/homepage/image5.png");
        break;
      case "signup-email-sent":
      case "recover-email-sent":
        setImageSrc("/images/homepage/image5.png");
        break;
    }
  }, [formType, isDrawerOpen]);

  useEffect(() => {
    console.log({ router: router.query });
    if (isValidFormType) {
      setFormType(router.query.formType as FormType);
      setIsDrawerOpen(true);
    }
  }, [router.query, isValidFormType]);

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleSign = (formType: FormType) => {
    setFormType(formType);
    handleOpenDrawer();
  };

  const handleBackButton = () => {
    router.push("/");
  };

  const handleGoogleSignin = () => {
    login("google");
  };

  const handleFacebookSignin = () => {
    login("facebook");
  };

  const handleToggleAgreement = () => {
    setIsSubscribeChecked(prev => !prev);
  };

  const contextDefaultValue = {
    formType,
    setFormType,
    isDrawerOpen,
    handleCloseDrawer,
    handleOpenDrawer
  };

  return (
    <Layout noHeader noFooter noNavBar>
      <Head>
        <meta name="theme-color" content={theme.palette.primary.main} />
      </Head>
      <Box
        sx={{
          position: "relative",
          height: "100vh",
          display: "flex",
          flexDirection: {
            sm: "column",
            md: "row"
          },
          alignItems: "center",
          justifyContent: "flex-end"
        }}>
        <Box
          sx={{
            position: "absolute",
            height: "100%",
            width: "100%",
            zIndex: -10,
            top: 0,
            left: 0,
            background: {
              xs: "linear-gradient(180deg, rgba(0, 0, 0, 0) -30%, #0D0D0D 99.9%)",
              md: "none"
            },
            transform: "matrix(-1, 0, 0, 1, 0, 0)"
          }}
        />
        <Box
          key={imageSrc}
          sx={{
            width: {
              xs: "100%",
              md: "50%"
            },
            height: "100%",
            position: {
              xs: "absolute",
              md: "relative"
            },
            animation: {
              md: "appear 0.5s ease-in"
            },
            "@keyframes appear": {
              "0%": {
                opacity: "0%"
              },
              "100%": {
                opacity: "100%"
              }
            }
          }}>
          <Image
            alt={"login-page-background-image"}
            className={"image"}
            src={imageSrc}
            layout="fill"
            quality={100}
            objectFit={"cover"}
            style={{ zIndex: -11 }}
          />
        </Box>
        {!(isDrawerOpen && isGreaterThanTablet) && (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: {
                xs: "100%",
                md: "50%"
              },
              px: {
                xs: 2,
                md: 6,
                lg: 12
              }
            }}>
            <Box
              sx={{
                mt: {
                  xs: 1,
                  md: 4
                },
                display: "flex",
                gap: 0.5,
                alignItems: "center",
                justifyContent: "end",
                alignSelf: {
                  md: "space-between"
                },
                width: "100%"
              }}>
              {!isGreaterThanTablet && (
                <IconButton
                  sx={{
                    ml: 0,
                    mr: "auto",
                    "& path": {
                      fill: { xs: "white", md: theme.palette.primary.main }
                    },
                    "& [stroke-width='1.2']": {
                      stroke: { xs: "white", md: theme.palette.primary.main }
                    }
                  }}
                  onClick={handleBackButton}>
                  <ArrowLeftIcon />
                </IconButton>
              )}
              <Typography
                sx={{
                  color: {
                    xs: "white.main",
                    md: "primary.light"
                  },
                  fontSize: {
                    md: 18
                  }
                }}>
                {t("already-registered")}
              </Typography>
              <Button
                color={"orange"}
                variant={"text"}
                sx={{
                  fontWeight: 600,
                  textTransform: "capitalize",
                  fontSize: {
                    md: 20
                  }
                }}
                data-cy={"sign_in_button"}
                onClick={() => handleSign("signin")}>
                {t("signin")}
              </Button>
            </Box>
            <Box sx={{ mt: 6.5, mb: { xs: "auto", md: 4 } }}>
              <Typography
                sx={{
                  mt: {
                    md: 16
                  },
                  color: {
                    xs: "white.main",
                    md: "primary.light"
                  },
                  fontSize: {
                    xs: 24,
                    lg: 34,
                    xl: 48
                  },
                  fontWeight: {
                    md: 400
                  }
                }}>
                {t("welcome")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                flexDirection: {
                  sm: "column"
                },
                gap: { xs: 1.25, md: 2 },
                maxWidth: {
                  md: 480
                }
              }}>
              <Button
                color={!isGreaterThanTablet ? "white" : "primary"}
                variant={"outlined"}
                onClick={handleGoogleSignin}
                sx={{
                  width: {
                    sm: "100%"
                  },
                  flex: 1,
                  height: 44,
                  borderColor: {
                    xs: "white.main",
                    md: "primary.light"
                  },
                  fontSize: {
                    xs: 16,
                    md: 18
                  },
                  color: {
                    md: "primary.light"
                  },
                  textTransform: {
                    md: "uppercase"
                  },
                  borderWidth: 1,
                  "& > svg": {
                    fontSize: 24,
                    marginLeft: 1.5
                  }
                }}>
                {isGreaterThanMobile ? t("signup-google") : ""}
                <GoogleIcon />
              </Button>
              <Button
                color={!isGreaterThanTablet ? "white" : "primary"}
                variant={"outlined"}
                onClick={handleFacebookSignin}
                sx={{
                  width: {
                    sm: "100%"
                  },
                  flex: 1,
                  height: 44,
                  borderColor: {
                    xs: "white.main",
                    md: "primary.light"
                  },
                  fontSize: {
                    xs: 16,
                    md: 18
                  },
                  color: {
                    md: "primary.light"
                  },
                  textTransform: {
                    md: "uppercase"
                  },
                  borderWidth: 1,
                  "& > svg": {
                    fontSize: 24,
                    marginLeft: 1.5,
                    "& path": {
                      fill: {
                        md: "#1877F2"
                      }
                    }
                  }
                }}>
                {isGreaterThanMobile ? t("signup-facebook") : ""}
                <FacebookIcon />
              </Button>
              <Button
                color={!isGreaterThanTablet ? "white" : "primary"}
                variant={"outlined"}
                sx={{
                  height: 44,
                  borderColor: {
                    xs: "white.main",
                    md: "primary.light"
                  },
                  color: {
                    md: "primary.light"
                  },
                  borderWidth: 1,
                  fontSize: {
                    xs: 16,
                    md: 18
                  },
                  textTransform: {
                    md: "uppercase"
                  }
                }}
                onClick={() => handleSign("signup")}
                fullWidth>
                {t("signup-email")}
              </Button>
              <Box px={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSubscribeChecked}
                      onChange={handleToggleAgreement}
                      sx={{ p: 0, "& .MuiSvgIcon-root": { fontSize: 14 } }}
                      icon={<RadioUnchecked style={{ fontSize: 20 }} />}
                      checkedIcon={<RadioChecked style={{ fontSize: 20 }} />}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        zIndex: 2,
                        color: {
                          xs: isSubscribeChecked ? "white.main" : "gray.dark",
                          md: isSubscribeChecked ? "primary.main" : "gray.dark"
                        }
                      }}>
                      {t("subscription")}
                    </Typography>
                  }
                  sx={{ alignItems: "start", gap: 2, mb: 3.5 }}
                />
              </Box>
              <Typography
                align={"center"}
                sx={{
                  color: {
                    xs: "white.main",
                    md: "primary.light"
                  },
                  fontWeight: {
                    md: 400
                  },
                  mb: 6,
                  zIndex: 5
                }}>
                {t("agreement.1") + " "}
                <DefaultLink
                  sx={{
                    color: {
                      xs: "white.main",
                      md: "primary.light"
                    },
                    fontWeight: {
                      md: 400
                    }
                  }}
                  href={"/terms"}>
                  {t("agreement.2") + " "}
                </DefaultLink>
                {t("agreement.3") + " "}
                <DefaultLink
                  sx={{
                    color: {
                      xs: "white.main",
                      md: "primary.light"
                    },
                    fontWeight: {
                      md: 400
                    }
                  }}
                  href={"/privacy-policy"}>
                  {t("agreement.4")}
                </DefaultLink>
              </Typography>
            </Box>
          </Box>
        )}
        <LoginFormContext.Provider value={contextDefaultValue}>
          {isGreaterThanTablet ? <LoginDrawerDesktop /> : <LoginDrawer />}
        </LoginFormContext.Provider>
      </Box>
    </Layout>
  );
};
